.slider {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  /* transform: translateX(-50%); */
  /* transition: all 1s ease; */


}
.current {
  opacity: 1;
  transform: translateX(0);
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
}

.slide img {
  height: 80vh;
}





.arrow {
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 4rem;

  position: absolute;
  z-index: 999;
}
.arrow:hover {
  color: rgb(207, 206, 206);
}

.next {
  top: 35%;
  right: 1.5rem;
}
.prev {
  top: 35%;
  left: 1.5rem;
}
