.nav-sidebar {
  width: 100vw;
  height: 100vh;
  background-color: #eae2b7e7;
  position: fixed;
  right: 0;
  top: 0;
  box-shadow: 2px 5px 5px var(--nav-drop-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100%);
  transition: all 300ms ease;
  opacity: 0;
  z-index: 1000;
}
.open {
  transform: translateX(0);
  opacity: 1;
}

.menu-close svg {
  font-size: 2rem;
  fill: var(--btn-bg);
  cursor: pointer;
  position: absolute;
  top: 2.25rem;
  right: 1.75rem;
}

.sidebar-btn-wrapper,
.sidebar-link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-size: 1.5rem;
  align-content: center;
  flex-wrap: wrap;
}

.sidebar-link-wrapper {
  flex-direction: column;
}

.sidebar-btn-wrapper {
  margin-bottom: 2rem;
  flex-direction: column;
}

.nav-sidebar .btn {
  font-size: 1.2rem;
}

/*Mobile View*/
@media (max-width: 425px) {
  .nav-sidebar .btn {
    font-size: 1rem;
  }
}
