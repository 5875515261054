.intro-Card{
    max-width: 25%;
    text-align: center;
    padding: 1.5rem 1rem ;
    line-height: 2;
    margin-top: 2rem;
    border-radius: .8rem;
    transition: all 800ms !important ;
}
.intro-Card:hover{
    background-color: var(--nav-body);
    color: var(--btn-bg);
    transform: translateY(25px);
}

.intro-card-icon{
    text-align: center;
}

.intro-card-icon svg{
    width: 50px ;
    height: 50px;  
}
.intro-card-title{
    font-size: 1.5rem;
    text-align: center;
}

.intro-card-desc{
    font-size: 1rem;
}