.home-main {
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
}

.home-main img {
  width: 100%;
}

.header {
  height: 70vh;
}

.home-pic img {
  width: 300px;
  text-align: center;
}

.home-pic {
  position: relative;
  /* background-image: linear-gradient(to bottom, transparent 70%, #111); */
  padding: 80px 0 40px 0;
  text-align: center;
  animation: glow ease-in-out 3s infinite;
}

@keyframes glow {
  0% {
    opacity: 0.6;
    filter: none;
  }
  100% {
    opacity: 1;
    filter: blur(1px);
  }
}

.home-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.type-wrap span {
  font-size: 2rem;
  color: rgba(234, 226, 183, 1);
}

/* .home-pic::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent 70%, #111);
} */

.content {
  padding: 3rem 0;
}

/* .grid {
  margin: 1rem 0.5rem 0;
} */

.winners {
  padding-top: 5rem;
}

.winners-heading {
  margin-bottom: 2.5rem;
  display: block;
  text-align: center;
  font-size: 2rem;
  color: white;
}

.objective {
  width: 40vw;
  /* margin-left: 30%;
  position: relative; */
  top: 8rem;
  text-align: center;
}

.objective span {
  font-weight: 600;
  font-size: 2rem;
}

.objective p {
  font-size: 1rem;
  color: #000;
}

.event-img img {
  width: 70%;
  height: 60%;
  margin-left: calc(6rem + 5vw);
  border-radius: 30px;
  border: 5px solid #333;
}

.sections {
  width: 80%;
  margin: 3rem auto;
  color: var(--nav-body);
}

/* IntroCards */
.intro-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.intro-head-hr {
  width: 170px;
  margin: auto;
}
.section-heading {
  margin-bottom: 2.5rem;
  display: block;
  text-align: center;
  font-size: 2rem;
  color: white;
  font-weight: 700;
  color: var(--btn-bg);
  text-transform: uppercase;
  line-height: 2;
}

/* Objective */
.section-text {
  max-width: 80%;
  text-align: center;
  padding: 2rem 0;

  padding-top: 0;
  margin: 0 auto;
  line-height: 2;
  text-align: justify;
}
.objective-sec {
  margin: 2rem auto;
  margin-bottom: 0;

  margin: 0 auto;
  line-height: 2;
}

/* Card Section */

.winner-cards {
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards {
  overflow: hidden;
  height: 180px;
  width: 600px;
  text-align: center;
  margin: 2rem 0;
  padding: 1.5rem 1rem 1rem;
  background-color: #eae2b7;
  box-shadow: 0 8px 16px 0 rgba(90, 90, 90, 0.2);
  border-radius: 15px;
}

.cards-content {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.cards img {
  width: 9vw;
  height: 9vw;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

.trophy {
  font-size: 5rem;
}

.cards h3 {
  color: #000;
  font-weight: 800;
  letter-spacing: 1.5px;
}

.cards h4 {
  font-size: 1.5rem;
  color: #000;
  font-weight: 600;
}

.cards span {
  color: rgb(46, 44, 44);
}

.container {
  padding: 2px 16px;
}
.rank {
  background-color: #d62828;
  padding: 10px 0.5rem;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}
.rank span {
  color: #eae2b7;
  font-size: 1.5rem;
}

@media (max-width: 998px) {
  .event-img img {
    margin-left: calc(4rem + 3vw);
    margin-top: calc(4rem + 2vh);
  }
  .winner-cards {
    display: flex;
    flex-direction: column;
    align-items: initial;
  }
  .cards {
    width: auto;
  }
}

@media (max-width: 468px) {
  .winner-cards {
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
  }
  .type-wrap span{
    font-size: 1.5rem;
  }
  .cards {
    padding: 0.8rem;
    height: 120px;
    width: 325px;
  }

  .cards img {
    width: 19vw !important;
    height: 19vw !important;
  }

  .trophy {
    font-size: 3rem;
  }
  .cards h3 {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }
  .cards h4 {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }

  .grid {
    display: block;
  }
  .event-img img {
    width: 100%;
    height: 100%;
    margin: 2rem 0;
    border-radius: 30px;
    border: 5px solid #333;
  }
  .section-text{
    max-width:100%;
    line-height: 1.6;
  }
}

.objective-section {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  /* top: -1rem; */
  height: 500px;
  width: 100%;
  /* width: 110%; */
  /* left: -13vh; */
  background-image: linear-gradient(
    to bottom,
    rgb(68, 65, 65),
    #eee,
    #eee,
    rgb(68, 65, 65)
  );
  -webkit-clip-path: polygon(50% 17%, 100% 0, 100% 84%, 50% 100%, 0 84%, 0 0);
  clip-path: polygon(50% 17%, 100% 0, 100% 84%, 50% 100%, 0 84%, 0 0);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#btntogglediv {
  /* height: 50px;
  width: 350px;
  position: relative;
  left: 36%; */
  display: block;
  margin: auto;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  border-color: transparent;
}

@media (max-width: 768px) {
  #btntogglediv {
    /* left: 28%;
    height: 3rem;
    width: 20rem; */
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .objective-section {
    height: 30rem;
    width: 100%;
    /* left: -6vh; */
  }
  .objective {
    width: 60vw;
  }

  .objective span {
    font-size: 1.8rem;
  }

  .objective p {
    font-size: 1rem;
  }
  .cards img {
    height: 20vh;
    width: 15vw;
  }
}
/* mobile view */

@media (max-width: 468px) {
  .intro-cards {
    flex-direction: column;
  }
  .intro-Card {
    max-width: 100% !important;
  }
  #btntogglediv {
    /* left: 23%;
    height: 2rem;
    width: 12rem; */
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
  .objective-section {
    height: 300px;
    /* width: 93vw;
    left: -6vh; */
  }
  .objective {
    width: 60vw;
  }

  .objective span {
    font-size: 1.5rem;
  }
  .objective p {
    font-size: 0.5rem;
  }
  .objective-sec{
    width: 93%;
  }
}

.footer {
  position: relative;
  top: -1.3rem;
  color: var(--nav-body);
}
