.member-card {
  width: 210px;
  /* height: 210px; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 0.75rem;
  transition: 0.3s ease-in-out;
  border-radius: 0.5rem;
  position: relative;
}

.member-card:hover {
  background-color: rgba(0, 0, 0, 0.308);
}

.card-img-container {
  width: 160px;
  height: 160px;
  border-radius: 10%;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.member-card:hover .card-img-container {
  border-radius: 50%;
}

.card-name {
  font-weight: 600;
  margin-block: 0.5rem;
  text-align: center;
}

.card-links {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  background-color: #111;
  top: 95%;
  left: 0;
  opacity: 0;
  height: 0;
  border-radius: 0 0 0.5rem 0.5rem;
  transition: 0.3s ease-in-out;
  z-index: 10;
}

.member-card:hover .card-links {
  height: 2.5rem;
  opacity: 1;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-link {
  display: grid;
  place-items: center;
}

.card-link svg {
  font-size: 1.5rem;
  color: aliceblue;
  transition: 0.2s ease-in-out;
}

.card-link:hover svg {
  transform: scale(1.1);
  color: hsl(0, 69%, 55%);
}

@media (max-width: 500px) {
  .member-card {
    width: 150px;
    /* min-height: 150px; */
  }
}

/*Mobile View*/
@media (max-width: 425px) {
  .card-name {
    text-align: center;
  }
  .member-card {
    height: auto;
    background-color: rgba(0, 0, 0, 0.308);
  }
  .card-img-container {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  }
  .card-links {
    height: 2.5rem;
    opacity: 1;
    position: initial;
    background-color: transparent;
    margin-top: auto;
  }
}

@media (max-width: 350px) {
  .member-card {
    padding: 12px 8px;
    width: 130px;
    min-height: 85px;
  }
}
