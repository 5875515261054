.user-login {
  margin: 10px 0;
  padding: 10px 3%;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  position: relative;
}
.user-img img {
  width: 500px;
}
form {
  width: 420px;
  margin: 20px 30px;
  padding: 10px;
}

h1 {
  margin-bottom: 20px;
}

.details {
  margin-bottom: 20px;
  font-size: 1.1rem;
  align-items: center;
}

.asterik {
  color: var(--btn-bg);
}

.name {
  /* display: grid;
  grid-template-columns: repeat(2, minmax(calc(8.2rem + 5vw), 0.3fr)); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.head-input {
  width: calc(9rem + 3vw);
}

.input-data {
  /* width: calc(21.4rem + 3vw); */
  width: 100%;
}
.input__field {
  height: 2.2rem;
  padding: 0.3rem 0.5rem;
  margin-top: 0.3rem;
  font-size: 0.85rem;
  border: 1.5px solid var(--btn-bg);
  box-sizing: border-box;
  border-radius: 5px;
}

.input__field:hover {
  outline: 1px solid var(--btn-bg);
}

.input__field:focus {
  border: none;
  outline: 0.5px solid var(--btn-bg);
  box-shadow: 0 0 3px 1px var(--btn-bg);
}

.otp__input__field {
  width: calc(10rem + 3vw);
  align-self: center;
}

.signin__input__field {
  width: calc(15rem + 3vw);
}
.otpVerify-page {
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.otpVerify-page h1,
.otpVerify-page .details,
.otpVerify-page .details input {
  text-align: center;
}
.otpVerify-page .login-signup-btn {
  display: block;
  margin: 0 auto;
}
.otpVerify-page form p,
.otpVerify-page form p ul li {
  font-size: 0.8rem;
}
.otpVerify-page form p {
  margin: 1rem 0;
}
.email-notify {
  color: var(--btn-bg);
}
.otp-bottom-notes {
  width: fit-content;
  margin: 1rem auto;
}
.email-name {
  color: yellow;
}
.details-input {
  width: 200px;
  height: 35px;
  font-size: 0.83rem;
  margin-top: 5px;
  border-radius: 4px;
  border: 2px solid var(--btn-bg);
}

.login-signup-btn {
  padding: 3%;
  width: 10rem;
  background-color: #eae2b7;
  border: 2px solid rgb(109, 107, 107);
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  margin-bottom: 20px;
}
.forgotPassword-links {
  color: var(--btn-bg);
  font-size: 0.95em;
  text-decoration: none;
  /* padding-top:1.2em; */
  /* margin: 6em; */
}

.links {
  color: rgb(180, 178, 178);
}
.links:hover {
  color: var(--btn-bg);
}

#password + span svg,
#confirmPassword + span svg {
  margin-top: 0.4rem;
  position: absolute;
  top: 0.48rem;
  right: 0.3rem;
  z-index: 100;
  color: rgba(151, 151, 151, 0.692);
}
.inline-input-svg {
  position: relative;
  width: fit-content;
}
.Cpass-btn {
  width: auto;
}

.forgot-password-wrapper {
  padding-top: 6rem;
}

.back-btn-login {
  position: absolute;
  top: 3rem;
  left: 3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.35rem 0.5rem;
  border: 1px solid white;
}
.back-btn-login p {
  font-size: 0.85rem;
}

/* @media (max-width: 868px) {
  .user-login {
    margin: 0 50px;
    padding: 5px 8%;
    flex-direction: column;
    width: auto;
  }
  .head-input {
    display: block;
    margin: 0 auto;
  }
} */

@media (max-width: 868px) {
  .user-login {
    margin: 0 50px;
    padding: 5px 8%;
    flex-direction: column;
    width: auto;
  }
  /* .head-input {
    display: block;
    margin: 0 auto;
  } */
  .user-img img {
    width: 250px;
  }
  form {
    margin: 0;
    padding: 20px;
  }

  .name {
    display: grid;
    grid-template-columns: repeat(2, minmax(calc(9rem + 5vw), 0.3fr));
  }

  .details {
    font-size: 0.8rem;
  }

  .login-signup-btn {
    position: relative;
    /* left: 20vw; */
  }
  .otp-bottom-notes {
    margin: 0;
  }
  .back-btn-login {
    top: 1.5rem;
    left: 1.5rem;
  }
  .forgot-password-wrapper {
    padding-top: 6rem;
  }
}

/* For Mobile Use ---> */
@media (max-width: 425px) {
  form {
    width: 370px;
  }
  .user-login {
    padding: 0;
    margin: 0 0;
  }
  h1 {
    font-size: 1.55rem;
    text-align: center;
    margin-bottom: 15px;
  }
  .user-img img {
    width: 175px;
  }
  .details {
    margin-bottom: 8px;
  }
  .name {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1fr, 2);
  }
  .head-input {
    margin-right: 0;
    width: 100%;
  }
  .login-signup-btn {
    position: static;
    display: block;
    margin: 0.4rem auto !important;
    margin-bottom: 1rem;
  }
  #password + span svg,
  #confirmPassword + span svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.3rem;
  }
  .inline-input-svg {
    width: 100%;
  }
  .otpVerify-page {
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  /*for signin page ----> */
  .useridPass {
    width: fit-content;
    margin: 0 auto;
  }
  .useridPass .details {
    width: fit-content;
  }
  #signin-heading {
    font-size: 1.15rem;
  }
  .user-login p {
    font-size: 0.9rem;
  }
  .otp__input__field {
    display: block;
    margin: 1.75rem auto;
    width: calc(10rem + 22vw);
    text-align: center;
  }
  .back-btn-login {
    top: 1.5rem;
    left: 1.5rem;
  }
  .forgot-password-wrapper {
    padding-top: 6rem;
  }
}
@media (max-width: 354px) {
  form {
    width: 250px;
    padding: 0;
    overflow-x: hidden;
  }
  h1 {
    font-size: 1.2rem;
  }
  .input-data {
    width: 100%;
  }
  .user-img {
    text-align: center;
  }
  /*for signin page ----> */
  #signin-heading {
    font-size: 0.87rem;
  }
  .back-btn-login {
    top: 1.5rem;
    left: 1.5rem;
  }
  .forgot-password-wrapper {
    padding-top: 6rem;
  }
}
