.member-filter {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.member-filter .btn {
  border-radius: 0.5rem;
}

.member-filter .btn.selected {
  color: var(--btn-bg);
  background-color: #fff;
}

.member-filter .btn:hover {
  transform: scale(1.05);
}

/*Mobile View*/
@media screen and (min-width: 320px) and (max-width: 425px) {
  .member-filter {
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 347px) {
  .member-filter .core {
    padding: 8px 9px;
  }
}
