.members-container {
  position: relative;
  width: 95%;
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem 0rem;
  justify-items: center;
  margin-top: 1.5rem;
}

@media (max-width: 1000px) {
  .members-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 750px) {
  .members-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .members-container {
    grid-template-columns: repeat(2, 150px);
  }
}
/*Mobile View*/
@media (max-width: 425px) {
  .members-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem 0;
  }
}
@media (max-width: 350px) {
  .members-container {
    gap: 2rem 0.7rem;
  }
}
