.table-container {
  margin-top: 1rem;
  position: relative;
}

.table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  overflow-y: scroll;
  max-height: 400px;
}

.table td,
.table th {
  padding: 12px 15px;
  text-align: center;
  font-size: 16px;
}

.table th:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.table th:nth-child(3) {
  border-right: 1px solid white;
}

.table td:nth-child(2) {
  border-left: 1px solid rgb(0, 0, 0);
  border-right: 1px solid rgb(0, 0, 0);
}
.table td:nth-child(3) {
  border-right: 1px solid rgb(0, 0, 0);
}

.table thead {
  position: sticky;
  z-index: 10;
  top: 0;
}

.table td {
  z-index: -1;
}

.table th {
  background-color: var(--btn-bg);
  color: #ffffff;
}

.table tbody tr:nth-child(even) {
  background-color: #d8d2cb;
}

.table tbody tr:nth-child(odd) {
  background-color: #eeeeee;
}

/*responsive*/

@media (max-width: 900px) {
  .table {
    display: none;
  }

  /* .table thead{
		display: none;
	}

	.table, .table tbody, .table tr, .table td{
		display: block;
		width: 100%;
	}
	.table tr{
		margin-bottom:15px;
	}
	.table td{
		text-align: right;
		padding-left: 50%;
		text-align: right;
		position: relative;
	}
	.table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:15px;
		font-weight: bold;
		text-align: left;
	} */
}
