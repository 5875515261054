.event-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  /* max-height: 100vh; */
  background-color: rgba(0, 0, 0, 0.432);
}
.show {
  opacity: 1;
  visibility: visible;
  display: block;
  z-index: 1000;
  overflow-y: auto;
}
.event-modal {
  transform: scale(0);
  margin: 2rem auto;
  width: 90%;
  max-width: 900px;
  background-color: var(--nav-body);
  position: relative;
  color: #333;
  padding: 1.5rem;
  border-radius: 0.5rem;
  transition: 200ms ease;
}
.modal-show {
  transform: scale(1);
}

.event-wrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.event-inputs {
  width: 55%;
  height: 100%;
}

.preview {
  width: 45%;
}

.close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}
.close-btn svg {
  font-size: 1.5rem;
  fill: var(--btn-bg);
}
.modal-date {
  font-size: 0.85rem;
  color: rgb(83, 83, 83);
  margin-bottom: 0.5rem;
}
.modal-text {
  font-size: 0.85rem;
  line-height: 1.15rem;
  margin-block: 1rem;
}
.event-modal .btn-disabled {
  background-color: gray;
}
.event-modal .btn-disabled:hover {
  transform: unset;
  box-shadow: unset;
}

.modal-textarea,
.modal-inp {
  width: 100% !important;
  border: none;
  outline: none;
  margin: 0 !important;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-family: Poppins, sans-serif;
}
.modal-textarea:focus,
.modal-inp:focus {
  outline: 1px solid var(--btn-bg);
}

.modal-textarea {
  min-height: 150px;
}

.input-wrapper {
  margin-top: 1rem;
}

.label-details{
  width: 100%;
  position: relative;
}

.add-event {
  margin-top: 1rem !important;
  border-radius: 0.25rem !important;
}

/* .date-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.date {
  width: 65%;
}
.duartion {
  width: 35%;
} */

.date-inp {
  background-color: #fff !important;
}

.modal-header {
  border-bottom: 1px solid var(--btn-bg);
}

.preview {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .preview {
    display: none;
  }
  .event-inputs {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .date-time {
    flex-direction: column;
    align-items: stretch;
  }

  .date {
    width: 100%;
  }
  .duartion {
    width: 100% !important;
  }
}

/*  Mobile View ---> */
@media (max-width: 425px) {
  .event-wrapper {
    justify-content: center;
  }
  .event-inputs {
    width: 90%;
  }
  .modal-header {
    text-align: center;
  }
  .add-event {
    display: block;
    margin: 0.5rem auto;
  }
}
