nav {
  width: 100%;
  padding: 0.7rem 4rem;
  background-color: var(--nav-body);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  box-shadow: 2px 2px 5px var(--nav-drop-shadow);
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.logo-image {
  width: 4rem;
  height: 4rem;
}

.logo-name {
  overflow: hidden;
  font-weight: 700;
  font-size: 1.25rem;
  text-transform: uppercase;
  line-height: 1;
}

.logo-motto {
  font-size: 0.95rem;
}

.nav-link-wrapper,
.nav-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.nav-link {
  color: #111111;
  font-weight: 500;
  font-size: 1.15rem;
  text-decoration: none;
  transition: all 300ms ease;
}
.active {
  font-weight: 600;
  color: var(--btn-bg);
}

.nav-btn-wrapper {
  gap: 1.5rem;
}

.menu-toggle-icon {
  display: none;
  cursor: pointer;
}

.menu-toggle-icon svg {
  font-size: 1.5rem;
  fill: var(--btn-bg);
}

.logged-user-image {
  width: 50px;
  /* border: 2px solid #1111111e; */
  border-radius: 100%;
  box-shadow:2px 2px 4px #1111119c;
}
.dashboard-image{
  border: none;
  width: 75px;
  box-shadow:2px 2px 4px 1px #6b686865;
}
.user-name-text {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: -5px;
}

@media (max-width: 1100px) {
  nav {
    padding: 1.5rem 3rem;
  }
  .nav-link-wrapper,
  .nav-btn-wrapper {
    gap: 2rem;
  }
}

@media (max-width: 900px) {
  nav {
    padding: 1.5rem 2rem;
  }
  .nav-link-wrapper,
  .nav-btn-wrapper {
    display: none;
  }
  .menu-toggle-icon {
    display: block;
  }
}

@media (max-width: 500px) {
  nav {
    padding: 1.5rem 1rem;
  }
  .logo-image {
    width: 3rem;
    height: 3rem;
  }
  .logo-name {
    font-size: 0.85rem;
  }
  .logo-motto {
    font-size: 0.65rem;
  }
}
