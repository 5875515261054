/* .event-card {
  width: 90%;
  max-width: 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    hsla(240, 30%, 28%, 0.25) 0px 50px 100px -20px,
    hsla(0, 0%, 0%, 0.7) 0px 30px 60px -30px;
  overflow: hidden;
  transition: all 300ms ease;
}
.event-card:hover {
  transform: translateY(-10px);
  box-shadow: hsla(240, 30%, 28%, 0.25) 0px 50px 100px -20px,
    hsla(0, 0%, 0%, 0.5) 0px 30px 60px -30px,
    hsla(210, 73%, 15%, 0.35) 0px -2px 6px 0px inset;
}

.event-card-img-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
  display: grid;
  place-items: center;
  position: relative;
}
.event-card-img-container::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.356), rgba(0, 0, 0, 0.171));
  opacity: 0;
  transition: all 300ms ease;
}
.event-card:hover .event-card-img-container::before {
  opacity: 1;
}

.event-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms ease;
}

.event-card:hover .event-card-img {
  width: 102%;
  height: 102%;
}

.event-card-title {
  width: 100%;
  background-color: var(--btn-bg);
  padding: 1rem;
  text-align: center;
  font-weight: 600;
} */

.event-card {
  width: 90%;
  max-width: 350px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    hsla(240, 30%, 28%, 0.25) 0px 50px 100px -20px,
    hsla(0, 0%, 0%, 0.7) 0px 30px 60px -30px;
  overflow: hidden;
  transition: all 300ms ease;
  background-color: var(--nav-body);
  padding: 0.75rem;
  color: #111;
}

.preview .event-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.event-card-img-container {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  display: grid;
  place-items: center;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}

.event-card-img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
  transition: all 300ms ease;
}

.card-text-details {
  width: 100%;
}

.event-card-title {
  font-weight: 600;
  min-height: 45px;
  font-size: 1.5rem;
  line-height: 1.45rem;
  margin-top: 1rem;
  align-self: flex-start;
}

.event-card-desc {
  height: 100px;
  overflow-y: scroll;
  margin-block: 0.5rem;
}

.event-card-desc p {
  font-size: 0.85rem;
  line-height: 1.2rem;
  text-align: justify;
}

.event-card-desc::-webkit-scrollbar {
  display: none;
}

.event-card-date {
  font-weight: 600;
  margin-top: 0.5rem;
}
.event-card-date span {
  font-weight: 400;
  border-radius: 0.25rem;
  padding: 0.1rem 0.45rem;
  background-color: #111;
  color: #fff;
  font-size: 0.85rem;
}
.event-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

/*  Mobile View ---> */
@media (min-width: 425px) {
  .event-card-btn {
    padding: 0.5rem 1.5rem !important;
  }
}

@media (max-width: 354px) {
  .event-card-btn {
    padding: 0.3rem 0.9rem !important;
  }
}
